@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary-50: #ffedec;
    --primary-100: #fedbd8;
    --primary-200: #feb8b1;
    --primary-300: #fd948a;
    --primary-400: #fd7163;
    --primary-500: #fc4d3c;
    --primary-600: #dd4030;
    --primary-700: #be3224;
    --primary-800: #9e2519;
    --primary-850: #7f170d;
    --primary-900: #600a01;

    --secondary-50: #ece8ff;
    --secondary-100: #ddd5fe;
    --secondary-200: #bdaffe;
    --secondary-300: #9e88fd;
    --secondary-400: #7e62fd;
    --secondary-500: #5f3cfc;
    --secondary-600: #5130e1;
    --secondary-700: #4225c6;
    --secondary-800: #3419aa;
    --secondary-850: #250e8f;
    --secondary-900: #170274;

    --grey-50: #f7f5f8;
    --grey-100: #efedf0;
    --grey-150: #deddde;
    --grey-200: #bcbbbd;
    --grey-300: #9b9a9c;
    --grey-400: #79787b;
    --grey-500: #58565a;
    --grey-600: #464548;
    --grey-700: #353436;
    --grey-800: #232224;
    --grey-850: #19181a;
    --grey-900: #121112;

    --success-50: #dbf5e4;
    --success-100: #c8efd5;
    --success-200: #a0e4b8;
    --success-300: #79d89a;
    --success-400: #51cd7d;
    --success-500: #2ac15f;
    --success-600: #229a4c;
    --success-700: #197439;
    --success-800: #114d26;
    --success-850: #0d3a1d;
    --success-900: #082713;

    --warning-50: #fdf9e8;
    --warning-100: #fbf4d1;
    --warning-200: #f8e9a3;
    --warning-300: #f4dd74;
    --warning-400: #f1d246;
    --warning-500: #edc718;
    --warning-600: #be9f13;
    --warning-700: #8e770e;
    --warning-800: #5f500a;
    --warning-850: #473c07;
    --warning-900: #2f2805;

    --danger-50: #ffeced;
    --danger-100: #ffd9db;
    --danger-200: #ffa3a6;
    --danger-300: #fe8e92;
    --danger-400: #fd686d;
    --danger-500: #fd4249;
    --danger-600: #cf353b;
    --danger-700: #a1282c;
    --danger-800: #731a1e;
    --danger-850: #5c1417;
    --danger-900: #450d0f;

    --info-50: #edf5fe;
    --info-100: #dcecfe;
    --info-200: #b9d8fc;
    --info-300: #95c5fb;
    --info-400: #72b1f9;
    --info-500: #4f9ef8;
    --info-600: #4085d4;
    --info-700: #316cb0;
    --info-800: #21548d;
    --info-850: #1a477b;
    --info-900: #123b69;
  }

  html {
    @apply bg-grey-850;
  }

  body {
    @apply font-dm-sans text-grey-50 font-normal antialiased;
  }

  *:focus-visible {
    @apply custom-focus;
  }
}

@layer components {
  .bg-gradient-primary {
    @apply bg-gradient-to-r from-[--primary-500] to-[#F78443];
  }

  .bg-gradient-secondary {
    @apply bg-gradient-to-r from-[--secondary-500] to-[#8F5DCF];
  }

  .custom-focus {
    @apply outline-primary-500 outline outline-2 outline-offset-4;
  }

  .text-with-icon-primary {
    @apply text-grey-50 [&_.oa:before]:text-grey-50;
  }

  .featured-title {
    @apply font-poppins text-with-icon-primary m-0 p-0 text-7xl font-light tracking-[0.01em] lg:text-8xl;
  }

  .title {
    @apply font-poppins text-with-icon-primary m-0 p-0 text-5xl font-light lg:text-6xl;
  }

  .subtitle {
    @apply font-poppins text-with-icon-primary m-0 p-0 text-3xl font-light lg:text-5xl;
  }

  .section-title {
    @apply font-poppins text-with-icon-primary m-0 p-0 text-2xl font-light lg:text-4xl;
  }

  .caption-title {
    @apply font-dm-sans text-with-icon-primary m-0 p-0 text-sm font-bold;
  }

  .caption-text {
    @apply font-dm-sans text-with-icon-primary m-0 p-0 text-sm font-normal;
  }

  .body-title {
    @apply font-dm-sans text-with-icon-primary m-0 p-0 text-base font-bold [&_.oa:before]:text-[1.5rem];
  }

  .body-text {
    @apply font-dm-sans text-with-icon-primary m-0 p-0 text-base font-normal [&_.oa:before]:text-[1.5rem];
  }
}

@layer utilities {
  .screen-full {
    @apply h-screen w-screen;
  }

  // You can use this class for debugging purposes:
  .ping {
    @apply rounded-md border border-red-600/50 bg-red-600/5 shadow;
  }

  .center {
    @apply flex items-center justify-center;
  }

  .between {
    @apply flex items-center justify-between;
  }
}
